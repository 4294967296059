.BigScreenPDP{
    .slick-slider{
        height: 100%;
        .slick-list{
            height: inherit;
            .slick-track{
                height: inherit;
                div{
                    height: inherit;
                }
            }
        }
    }
}
