@font-face{
    font-family: 'Carpetright';
    src: url('./fonts/CircularStd-Bold.otf');
}

html{
    @media only screen and (min-width: 1500px){
        font-size: 0.80vw;
    }
    
}

body{
    font-family: 'Carpetright';
}

.slick-prev{
    left: 0;
}
.slick-next{
    right: 0;
}

.slick-prev, .slick-next{
    z-index: 5;
    width: 4vw;
    height: 7vw;

    img{
        transition: opacity 0.2s;
        opacity: 1;
    }
}

.slick-prev:before, .slick-next:before{
    content: unset;
    color: black;
    z-index: 5;
    font-size: 4vw;
}

.slick-disabled{
    img{
        opacity: 0%;
    }
}

.BigScreen-Product-Slideshow{
    .slick-slider.slick-initialized{
        height: 100%;
        .slick-list{
            height: 100%;
            .slick-track{
                height: 100%;
                .slick-slide > div{
                    height: 100%;
                }
            }
        }
    }
}

.pt-full{
    padding-top: 100%;
}

.footer-icons{
    img{
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        padding: 5px;
        margin-right: 1rem;
    }
}

.hg-small{
    max-width: 90px;
}
.mw-unset{
    max-width: unset !important;
}

.hg-theme-default{
    padding: 25px;
    .hg-row:not(:last-child){
        margin-bottom: 15px;
    }
    .hg-row .hg-button:not(:last-child){
        margin-right: 15px;
    }
}

.hg-theme-default .hg-button{
    height: 4rem;
}

.hg-button.hg-functionBtn.hg-button-numbers{
    max-width: 90px;
}

.simple-keyboard.hg-theme-default{
    .hg-button.hg-shade{
        background-color: #c1c1c1;
    }
    .hg-button.hg-dark{
        color: white;
        background-color: #4a4a51;
    }
}

.product-block__container{
    width: 40%;
}

.slick-initialized {
    overflow: hidden;
}


.wishlist-remove__button{
    div{
        transform-origin: 125% 0%;
        transform: rotate(45deg);
    }
}

.heart-icon__animation {
    div{
        transition: transform .5s ease;
        transform-origin: 0% 0%;
    }
    svg {
        transition-property: fill, stroke;
        transition-duration: 500ms;
    }
}

.heart-icon__dark.heart-icon__animation-end{
    svg{
        fill: white;
    }
}

.heart-icon__animation-start{
    div{
        transform: rotate(0deg) translate(-50%, -50%);;
    }
    svg{
        fill: none;
        stroke: white;
    }
}

.heart-icon__animation-end{
    div{
        transform: rotate(45deg) translate(-50%, -50%);;
    }
    svg{
        fill: black;
        stroke: transparent;
    }
}

.help-popup__list{
    li{
        margin-bottom: 1rem;
        position: relative;
        &::before{
            position: absolute;
            width: 1.8rem;
            left: -40px;            
        }
    }
}

.content__QR{
    &::before{
    content: url("/images/QRCode.svg");
    }
}

.content__Heart {
    &::before{
    content: url("/images/HeartIcon.svg");
    }
}

.content__MultiQR {
    &::before{
    content: url("/images/MultiQRCode.svg");
    }
}

.content__Monitor {
    &::before{
    content: url("/images/BigScreenIcon.svg");
    }
}

.v-gradient__black{
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.61) 0%, rgba(0,0,0,0.6) 1%, rgba(0,0,0,0.33) 45%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.61) 0%,rgba(0,0,0,0.6) 1%,rgba(0,0,0,0.33) 45%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0.61) 0%,rgba(0,0,0,0.6) 1%,rgba(0,0,0,0.33) 45%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9c000000', endColorstr='#00ffffff',GradientType=0 );
}

.mh-inherit{
    max-height: inherit;
}

.bg-img-transparent{
    background-image: transparent;
}

.read-more__gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+1,ffffff+64,ffffff+100&0+0,0+64,1+100 */
background-image: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,0) 64%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background-image: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,0) 64%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background-image: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,0) 64%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

}

.slick-slider{
    max-height: inherit;
    .slick-list{
        max-height: inherit;
    }
}

.slick-dots{
    bottom: 20px;
    li {
        margin: 0 10px;
        
        button{
            height: 30px;
            width: 30px;
            &::before{
                font-size: 30px;
                height: 30px;
                width: 30px;
                opacity: 1;
                -webkit-text-fill-color: #0000001c;
                -webkit-text-stroke-color: white;
                -webkit-text-stroke-width: 2px;
            }
        }
    }
    .slick-active{
        button::before{
            -webkit-text-fill-color: white;
        }
    }
}

.cr-product__heading-text-dark{
    color: white;
    -webkit-text-fill-color: black;
    -webkit-text-stroke-width: 1px;
}

.cr-product__heading-text{
    color: black;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
}

.center-heart-icon {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.before-border:before {
    content: " ";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0px;
    border-bottom: 1px solid white;
}

.bg-black-white-vertical {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1f201a+0,1f201a+49,ffffff+50,ffffff+100 */
    background: #1f201a; /* Old browsers */
    background: -moz-linear-gradient(top, #1f201a 0%, #1f201a 49%, #ffffff 50%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #1f201a 0%,#1f201a 49%,#ffffff 50%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #1f201a 0%,#1f201a 49%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f201a', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

.tick-list{
    list-style: none;
    padding-left: 0;
    li{
        position: relative;
        padding-left: 2.5rem;
    }
    li:before{
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        width: 2.5rem; 
        height: 2.5rem;
        color: #857755;
        background: url("/images/tick.svg") no-repeat;
        
    }
}